import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Image from 'react-bootstrap/Image';

function Expertise() {
  const [selectedTab, setSelectedTab] = React.useState(0);
  return (
    <section className="expertise-container" id="expertise">
      <Container className="container-xxl p-0">
        <h2 className="container-title">What We Build</h2>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row>
            <Col lg={4} xs={12}>
              <Nav as={Row} variant="pills" className="d-flex flex-lg-column flex-md-row gx-2 gy-4">
                <Nav.Item as={Col} xs={4} lg={12} className="d-flex">
                  <Nav.Link
                    onClick={() => setSelectedTab(0)}
                    className={selectedTab === 0 ? 'bg-gradient' : ''}
                    eventKey="first"
                  >
                    Blockchain{'\n'}Development
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as={Col} xs={4} lg={12} className="d-flex">
                  <Nav.Link
                    onClick={() => setSelectedTab(1)}
                    className={selectedTab === 1 ? 'bg-gradient' : ''}
                    eventKey="second"
                  >
                    Blockchain{'\n'}Consulting
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as={Col} xs={4} lg={12} className="d-flex">
                  <Nav.Link
                    onClick={() => setSelectedTab(2)}
                    className={selectedTab === 2 ? 'bg-gradient' : ''}
                    eventKey="third"
                  >
                    IoT{'\n'}Development
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col lg={{ span: 7, offset: 1 }} xs={12} className="px-4">
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <TabContent1 />
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <TabContent2 />
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <TabContent3 />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </section>
  );
}

function TabContent1() {
  return (
    <Stack className="tab-content-stack">
      <TabContentItem
        img="/assets/icons/dap-icon.svg"
        title="Decentralized Applications and Protocols"
        description="DEX, DeFi, DAO, Stablecoins, Staking Platform."
      />
      <TabContentItem
        img="/assets/icons/nft-icon.svg"
        title="NFT / Metaverse / GameFi"
        description="NFT Marketplace, NFT Token Creation, NFT Staking Platform, Play2Earn and other crypto games (3D modeling, animation, Unreal Engine)."
      />
      <TabContentItem
        img="/assets/icons/cryptowallet-icon.svg"
        title="Crypto Wallet"
        description="Web/Mobile wallet, Non-Custodial wallet, Custodial wallet integration."
      />
      <TabContentItem
        img="/assets/icons/blockchain-icon.svg"
        title="Blockchain"
        description="Permissioned private blockchains from scratch, EVM-compatible blockchains, Cosmos SDK, Smart Contract Development."
      />
      <TabContentItem
        img="/assets/icons/hardware-wallet-icon.svg"
        title="Hardware Wallets"
        description="Ledger Embedded App, Ledger Live App, Trezor App."
      />
    </Stack>
  );
}

function TabContent2() {
  return (
    <Stack className="tab-content-stack">
      <TabContentItem
        img="/assets/icons/idea-validation-icon.svg"
        title="Project idea validation"
        description="Assessing project feasibility and providing recommendations."
      />
      <TabContentItem
        img="/assets/icons/blockchain-network-icon.svg"
        title="Selection of the appropriate blockchain network"
        description="Analyzing different blockchain networks and selecting the most suitable one based on the project requirements and goals."
      />
      <TabContentItem
        img="/assets/icons/technologies-icon.svg"
        title="Determination of the technology stack"
        description="Identifying the best suited programming languages, frameworks, and tools for the project implementation."
      />
      <TabContentItem
        img="/assets/icons/project-planning-icon.svg"
        title="Project breakdown and planning"
        description="Breaking down the project into manageable tasks, defining milestones and creating a project plan."
      />
      <TabContentItem
        img="/assets/icons/phases-icon.svg"
        title="Phases estimation and deliverables determination"
        description="Estimating project timeline and resources required for each project phase and determining the expected deliverables for each phase."
      />
      <TabContentItem
        img="/assets/icons/project-architecture-icon.svg"
        title="Project architecture definition"
        description="Designing the overall architecture of the project, including the data structure, business logic, and user interface design."
      />
    </Stack>
  );
}

function TabContent3() {
  return (
    <Stack className="tab-content-stack">
      <TabContentItem
        img="/assets/icons/architecture-design-icon.svg"
        title="Architecture design"
        description="System architecture, connectivity, and data flow."
      />
      <TabContentItem
        img="/assets/icons/hardware-development-icon.svg"
        title="Hardware development"
        description="Circuit design, sensors, controllers, gateways, actuators."
      />
      <TabContentItem
        img="/assets/icons/software-development-icon.svg"
        title="Software development"
        description="Custom software components, including firmware, device drivers, and cloud-based services."
      />
      <TabContentItem
        img="/assets/icons/protocols-icon.svg"
        title="Connectivity and communication protocols"
        description="Communication protocols and technologies for IoT devices."
      />
      <TabContentItem
        img="/assets/icons/analytics-icon.svg"
        title="Data management and analytics"
        description="Data management strategy and custom analytics solutions for IoT-generated data."
      />
      <TabContentItem
        img="/assets/icons/security-icon.svg"
        title="Security and privacy"
        description="Security and privacy measures for IoT devices and data."
      />
      <TabContentItem
        img="/assets/icons/deployment-icon.svg"
        title="Integration and deployment"
        description="Integration with other systems and platforms, deployment on-premises or in the cloud."
      />
    </Stack>
  );
}

function TabContentItem({ img, title, description }) {
  return (
    <div className="tab-content-item">
      <div>
        <Image fluid src={img} alt="" />
      </div>
      <span className="title">{title}</span>
      <span className="description">{description}</span>
    </div>
  );
}

export default Expertise;
