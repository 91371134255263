export { default as scrollSpy } from './ScrollSpy';


function getStyleProperty(el, property, defaultValue = 0) {
  try {
    if (window.getComputedStyle) {
      return +document.defaultView.getComputedStyle(el, null).getPropertyValue(property).replace(/[a-zA-Z]+/, '');
    } else if (el.currentStyle) {
      return +el.currentStyle[property].replace(/[a-zA-Z]+/, '');
    }
    return defaultValue;
  } catch (e) {
    console.error(e);
    return defaultValue;
  }
};


export function scrollTo(e, menuOpen = false) {
  e.preventDefault();
  const target = document.getElementById(
    e.currentTarget.href.split("#")[1]
  );
  if (target) {
    const Nav = document.getElementById('navbar-navigation');
    let y = 0;
    switch (target.id) {
      case "expertise":
      case "tech-stack":
      case "portfolio":
      case "how-we-work":
      case 'contact-us':
      case "testimonials": {
        const containerTitle = document.querySelector(`#${target.id} .container-title`);

        if (containerTitle) {
          const btn = document.getElementById('collapse');
          y = (containerTitle.getBoundingClientRect().top + (window.pageYOffset || document.documentElement.scrollTop))
            - getStyleProperty(btn, 'height', 56)
            - getStyleProperty(Nav, 'padding-top', 24)
            - (getStyleProperty(Nav, 'padding-bottom', 24) * 2);
        }
        break;
      }
      case "intro": {
        if (menuOpen) document.getElementById('collapse').click();
        break;
      }
      default: break;
    }
    window.scrollTo({ top: y })
  }
};

export function setScrollPaddings(newPadding) {
  const headerContentHeight = document.getElementById('collapse').offsetHeight || 56;

  const headerHeight = headerContentHeight + (newPadding * 2);
  document.querySelector('html').style.scrollPaddingTop = `${headerHeight}px`;
  document.querySelector('body').style.paddingTop = `${headerHeight}px`;
};
