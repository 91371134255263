import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';

function Portfolio() {
  return (
    <section className="portfolio-container" id="portfolio">
      <Container fluid className="p-0 container-xxl">
        <h2 className="container-title text-center">Portfolio</h2>
        <Stack className="portfolio-list">
          {portfolio.map((p) => (
            <PortfolioItem {...p} key={p.name} />
          ))}
        </Stack>
      </Container>
    </section>
  );
}

const portfolio = [
  {
    name: 'fintech',
    direction: 'text-image',
    title: 'Fintech\nblockchain project',
    description:
      'The undisclosed Fintech solution that offers a secure, fast and cost-effective way for B2B users to make international money transfers in multiple currencies. The product is built on a permissioned blockchain, designed from scratch, that seamlessly integrates into the financial sector with an IBAN-compliant account format. Using a custom PoA consensus, the solution provides immediate transaction finality and relatively high TPS with low infrastructure costs.\n\nThe microservice architecture around the blockchain enables easy scalability, while user-friendly mobile and web applications allow users to manage their finances in one place. At the end of the project, the team continued to work together and officially launched as Cryptide, a full-service development company specializing in decentralized applications.',
    industries: ['DeFi', 'FinTech', 'Blockchain', 'Wallet'],
    technologies: [
      'Golang',
      'CockroachDB',
      'Redis',
      'LevelDB',
      'Apache Kafka',
      'Blockchain',
      'React',
      'React Native',
      'NextJS',
      'RxDB',
    ],
    image: 'assets/portfolio-fintech.png',
  },
  {
    name: 'pontem',
    direction: 'image-text',
    title: 'Aptos Ledger App',
    cooperation: 'Pontem Network',
    description:
      'First developed Aptos Network support for hardware wallets. Development began with the  Ledger Embedded Application that enables users to manage their APT crypto assets via their Ledger devices. The Aptos blockchain relies on the Ed25519 public-key signature system and the BCS (Binary Canonical Serialization) data format.\n\nDuring the development of the application, a BCS format deserialization library in the C language was created, which can be used separately. Additionally, Ledger device support was integrated into a Pontem companion wallet,  which is a Metamask-like browser extension built with the React framework.',
    industries: ['Hardware Wallet', 'Ledger', 'Aptos'],
    technologies: ['C', 'APDU', 'BOLOS', 'BCS', 'TypeScript', 'React'],
    image: 'assets/portfolio-pontem.png',
    website: 'https://pontem.network/pontem-wallet',
  },
  {
    name: 'liquidswap',
    direction: 'text-image',
    title: 'Liquidswap Harvest',
    cooperation: 'Pontem Network',
    description:
      'Our team contributed to the development of smart contracts for the Liquidswap Harvest project, an innovative staking program built on the Aptos blockchain. Liquidswap, the first Automated Market Maker (AMM) on the Aptos blockchain, enables secure and decentralized token swaps using smart contracts developed by the Pontem Network team. As part of the Liquidswap Harvest project, we focused on creating smart contracts that support permissionless staking pool creation, versatile staking and reward assets, NFT-based reward boosts, flexible reward deposits, secure unstaking, and easy reward withdrawals.',
    industries: ['Staking', 'NFT', 'DeFi', 'Aptos'],
    technologies: ['Rust', 'Move', 'Aptos CLI'],
    image: 'assets/portfolio-liquidswap.png',
    website: 'https://liquidswap.com',
  },
  {
    name: 'nftstays',
    direction: 'image-text',
    title: 'NFTStays',
    cooperation: 'SolidBlock',
    description:
      'A revolutionary way of hotel booking made possible by replacing traditional coupons with NFTs. The NFTs are used as a unique digital asset to represent customer reservations, providing a secure and convenient way to handle transactions. \n\nWhile users benefit from the increased flexibility and the ability to resell the unused portions of NFT coupons, hotels benefit from the new marketing and branding opportunities.',
    industries: ['DApp', 'NFT', 'Ethereum', 'Polygon'],
    technologies: ['Solidity', 'TypeScript', 'NestJS', 'PostgreSQL', 'React'],
    image: 'assets/portfolio-nftstays.png',
  },
  {
    name: 'mpc',
    direction: 'text-image',
    title: 'MPC Crypto Wallet',
    description:
      'The MPC Crypto Wallet PoC offers a practical solution for managing cryptographic keys and secrets. The technology is based on the blockchain-crypto-mpc library released by Unbound Security, which utilizes secure multiparty computation (MPC) to ensure high-level security for sensitive data. \n\nThe main task was to successfully compile the MPC crypto library as a WASM module and implement build tools suitable for use in CI systems. A PoC of a working protocol based on a Python web-socket server with a browser-based JavaScript client using the WASM module has been provided.',
    industries: ['Blockchain', 'Wallet', 'MPC'],
    technologies: ['C++', 'JavaScript', 'Python', 'WASM', 'Websockets'],
    image: 'assets/portfolio-mpc.png',
  },
  {
    name: 'totem',
    direction: 'image-text',
    title: 'Totem',
    cooperation: 'IT-Dimension',
    description:
      'A platform that simplifies Web3 integration for game developers and enhances the gaming experience for players. Using NFTs as digital assets, developers can easily integrate Web3 technology and offer greater flexibility and creative freedom. \n\nPlayers can explore assets, find games, and easily create a crypto wallet through social media or existing providers. Totem offers an innovative solution that streamlines the process for developers and enhances the gaming experience for players.',
    industries: ['GameFi', 'NFT', 'Ethereum', 'Polygon'],
    technologies: [
      'Solidity',
      'OpenZeppelin',
      'TypeScript',
      'NestJS',
      'Serverless',
      'gRPC',
      'MongoDB',
      'Redis',
    ],
    image: 'assets/portfolio-totem.png',
    website: 'https://totem-explorer.com/',
  },
  {
    name: 'tokenization-platform',
    direction: 'text-image',
    title: 'Tokenization Platform',
    description:
      'This is an innovative platform that enables real estate owners to utilize tokenization to prepare their assets for the blockchain economy. Tokenization involves breaking down an asset into tokens or shares, which streamlines the process of raising capital for asset or fund owners and provides investors with greater access to private real estate investments, transparency, and liquidity.\n\nThe platform is backed by Security Tokens that implement the ERC-1404 standard, which defines the rules for transferring tokens between addresses within established limitations. These tokens are designed to comply with regulatory requirements for token issuance, including requirements for collecting investor information, restrictions on token sales, and control of token transfers, which is particularly crucial in the field of real estate.',
    industries: ['DeFi', 'Ethereum', 'Polygon'],
    technologies: ['Solidity', 'OpenZeppelin', 'FortressTrust'],
    image: 'assets/portfolio-tokenization-platform.png',
  },
];

function PortfolioItem({
  name,
  title,
  cooperation,
  description,
  industries,
  technologies,
  image,
  direction,
  website,
}) {
  return (
    <div className="portfolio-item">
      <Row>
        {direction === 'image-text' && (
          <Col xs={12} xl={6} className="d-flex align-items-center">
            <Image src={image} fluid />
          </Col>
        )}
        <Col xs={12} xl={direction === 'text-image' ? 5 : { span: 5, offset: 1 }}>
          <Stack className="portfolio-item-info">
            <span className="portfolio-item-title">{title}</span>
            {cooperation && (
              <span className="portfolio-item-subtitle">
                In cooperation with the <b>{cooperation}</b>
              </span>
            )}
            <div className={`portfolio-industries ${name}-item`}>
              {industries.map((i) => (
                <span key={i}>{i}</span>
              ))}
            </div>
            <span className="portfolio-item-description">{description}</span>
            <div className="portfolio-technologies">
              {technologies.map((i) => (
                <span key={i}>{i}</span>
              ))}
            </div>
            {website && (
              <Button
                className={`visit-website-btn ${name}-item d-flex`}
                href={website}
                target="_blank"
              >
                <span>Visit Website</span>
                <img src="assets/icons/external-link.svg" alt="" />
              </Button>
            )}
          </Stack>
        </Col>
        {direction === 'text-image' && (
          <Col
            xs={{ span: 12, order: 'first' }}
            xl={{ span: 6, offset: 1, order: 'last' }}
            className="d-flex align-items-center"
          >
            <Image src={image} fluid />
          </Col>
        )}
      </Row>
    </div>
  );
}

export default Portfolio;
