import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import { scrollTo } from '../utils';

function shuffleArray(array) {
  let currentIndex = array.length;
  let randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
}

const cryptidians = [
  {
    id: 0,
    image: 'assets/employee1.jpeg',
    url: 'https://www.linkedin.com/in/vladyslav-bielokhin-a93599148/',
  },
  {
    id: 1,
    image: 'assets/employee2.jpeg',
    url: 'https://www.linkedin.com/in/elizabeth-troianova-55b7b6145/',
  },
  {
    id: 2,
    image: 'assets/employee3.jpeg',
    url: 'https://www.linkedin.com/in/jackdrakkar/',
  },
  {
    id: 3,
    image: 'assets/employee4.jpeg',
    url: 'https://www.linkedin.com/in/yaroslav-reshetnyk-06a2036b/',
  },
  {
    id: 4,
    image: 'assets/employee5.jpeg',
    url: 'https://www.linkedin.com/in/artem-turlay-b99a64130/',
  },
  {
    id: 5,
    image: 'assets/employee6.jpeg',
    url: 'https://www.linkedin.com/in/vldmkr/',
  },
  {
    id: 6,
    image: 'assets/employee7.jpeg',
    url: 'https://www.linkedin.com/in/genaliuk/',
  },
];

function Intro() {
  return (
    <section className="intro-container" id="intro">
      <Image
        src="assets/intro-bg.svg"
        className="container-bg position-absolute start-50 translate-middle"
      />
      <Container fluid className="p-0 container-xll position-relative">
        <Row>
          <Col xs={12} className="d-flex justify-content-center">
            <img src="/assets/union-logo.svg" alt="Union logo" />
          </Col>
        </Row>
        <Row>
          <Col
            lg={{ span: 10, offset: 1 }}
            xs={12}
            className="intro-title-wrapper px-3 d-flex justify-content-center"
          >
            <span className="intro-title py-2">
              Unlock the potential of Web3 and IoT with Cryptide
            </span>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="intro-description-wrapper d-flex justify-content-center p-0">
            <span className="intro-description">
              A team of experts specializing in decentralized application development and blockchain
              consulting services. We provide full-cycle development for your product, from in-depth
              research to architecture design, MVP implementation, and ongoing support.
            </span>
          </Col>
        </Row>
        <Row>
          <Col xl={4} className="d-flex d-xl-none justify-content-center">
            <Button variant="primary" className="bg-gradient what-we-build-btn" href="#expertise" onClick={scrollTo}>
              What We Build&nbsp;&nbsp;🔥
            </Button>
          </Col>

          <Col xl={4} xs={12}>
            <div className="partners-block d-flex flex-row justify-content-center">
              <div className="partner-item d-flex justify-content-center">
                <Image src="/assets/partner-pontem.svg" fluid className="my-auto" />
              </div>
              <div className="partner-item d-flex justify-content-center">
                <Image src="/assets/partner-solidblock.svg" fluid className="my-auto" />
              </div>
              <div className="partner-item d-flex justify-content-center">
                <Image src="/assets/partner-artinbits.png" fluid className="my-auto" />
              </div>
            </div>
            <Row>
              <Col xs className="text-center pt-3">
                <span className="text">our main partners</span>
              </Col>
            </Row>
          </Col>

          <Col xl={4} className="d-none d-xl-flex justify-content-center">
            <Button href="#expertise" variant="primary" className="bg-gradient what-we-build-btn">
              What We Build&nbsp;&nbsp;🔥
            </Button>
          </Col>

          <Col xl={4} className="p-0">
            <Row className="team-block">
              <Col xl={12} className="d-flex justify-content-center team-container">
                {shuffleArray(cryptidians).map((c, i) => {
                  if (i <= 4) {
                    return (
                      <img
                        key={c.id}
                        src={c.image}
                        onClick={() => window.open(c.url, '_blank')}
                        alt={`Employee ${i + 1}`}
                        className="rounded-circle profile-image"
                      />
                    );
                  }
                  if (i === 5) {
                    return (
                      <div
                        key={c.id}
                        className="rounded-circle profile-image z-0 text"
                        onClick={() =>
                          window.open(
                            'https://www.linkedin.com/company/cryptidedev/people/',
                            '_blank'
                          )
                        }
                      >
                        <span>+2</span>
                      </div>
                    );
                  }
                  return null;
                })}
              </Col>
            </Row>
            <Row>
              <Col xs className="text-center pt-3">
                <span className="text">powerful team ready for challenges</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Intro;
