import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

function HowWeWork() {
  return (
    <section className="how-we-work-container" id="how-we-work">
      <Container fluid className="p-0 container-xxl">
        <h2 className="container-title text-center">How We Work</h2>
        <Row className="g-3">
          <Col xs={12} xl={4} className="d-flex">
            <Stack className="work-process-card">
              <span className="work-process-card-header">
                <span>1</span> stage
              </span>
              <Image src="assets/work-process-1.png" />
              <span className="work-process-card-title">Investigation & Discovery</span>
              <span className="work-process-card-description">
                Discovery phase bridges a gap between product theoretical concept and its technical
                implementation by identifying customer needs and product strengths and weaknesses.
                It helps to find the best solutions for product development both from a business and
                technical point of view.
              </span>
            </Stack>
          </Col>
          <Col xs={12} xl={4} className="d-flex">
            <Stack className="work-process-card">
              <span className="work-process-card-header">
                <span>2</span> stage
              </span>
              <Image src="assets/work-process-2.png" />
              <span className="work-process-card-title">Development & Release</span>
              <span className="work-process-card-description">
                The Development phase involves coding, integrating, testing, and deploying software
                to production environments for end-user release. The goal is to create a functional
                product that meets the needs of the customer.
              </span>
            </Stack>
          </Col>
          <Col xs={12} xl={4} className="d-flex">
            <Stack className="work-process-card">
              <span className="work-process-card-header">
                <span>3</span> stage
              </span>
              <Image src="assets/work-process-3.png" />
              <span className="work-process-card-title">Maintenance & Support</span>
              <span className="work-process-card-description">
                In the Maintenance & Support phase, the focus is on ensuring that the system remains
                functional, secure, and effective over time. This includes ongoing support,
                maintenance, and updates to address any issues that arise as users interact with the
                system.
              </span>
            </Stack>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default HowWeWork;
