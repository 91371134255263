import React from 'react';
import SSRProvider from 'react-bootstrap/SSRProvider';

import ThemeProvider from 'react-bootstrap/ThemeProvider';
import NavigationBar from './components/navbar';
import Intro from './components/intro';
import Expertise from './components/expertise';
import TechStack from './components/tech-stack';
import HowWeWork from './components/how-we-work';
import Portfolio from './components/portfolio';
import Testimonials from './components/testimonials';
import ContactUs from './components/contact-us';

import './styles/App.scss';

const App = () => (
  <SSRProvider>
    <ThemeProvider breakpoints={['xxl', 'xl', 'lg', 'md', 'sm', 'xs']}>
      <NavigationBar />
      <Intro />
      <Expertise />
      <TechStack />
      <HowWeWork />
      <Portfolio />
      <Testimonials />
      <ContactUs />
    </ThemeProvider>
  </SSRProvider>
);

export default App;
