import React, { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { scrollTo, scrollSpy, setScrollPaddings } from '../utils';

function NavigationBar(props) {
  const [scroll, setScroll] = React.useState(false);
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const [isScrollingUp, setIsScrollingUp] = React.useState(false);
  const [menuOpen, setMenuOpen] = React.useState(false);

  useEffect(() => {
    const Nav = document.getElementById('navbar-navigation');
    const defaultPadding = 24;

    const newPaddingAdditionalValue = scrollPosition ? scrollPosition / 2 : 0;
    const newPadding = isScrollingUp ? (defaultPadding + newPaddingAdditionalValue) : (defaultPadding - newPaddingAdditionalValue);

    if (newPadding >= 12 && newPadding <= 24) {
      setScrollPaddings(newPadding);
      Nav.style.paddingTop = `${newPadding}px`;
      Nav.style.paddingBottom = `${newPadding}px`;
    } else {
      setScrollPaddings(defaultPadding / 2);
      Nav.style.paddingTop = `${defaultPadding / 2}px`;
      Nav.style.paddingBottom = `${defaultPadding / 2}px`;
    }
  }, [scrollPosition, isScrollingUp]);

  useEffect(() => {
    const setScrollPos = () => {
      setScrollPosition(window.scrollY);
      setIsScrollingUp(scrollPosition > window.scrollY);
      setScroll(window.scrollY > 10);
    };

    setScrollPos();

    window.addEventListener('scroll', () => {
      setScrollPos();
    });
    window.addEventListener('resize', () => {
      setScrollPos();
    });

    const Nav = document.getElementById('navbar-navigation');
    scrollSpy(Nav, {
      sectionSelector: 'section',
      targetSelector: '.nav-link',
      offset: Nav.offsetHeight + 1,
      activeClass: 'active',
      hiddenId: 'intro'
    });
  }, []);

  return (
    <Navbar
      id="navbar-navigation"
      fixed='top'
      collapseOnSelect
      expand="xl"
      className={`bg-white ${scroll ? 'shadow-sm' : ''}`}
      onToggle={setMenuOpen}
    >
      <Navbar.Brand>
        <a role='button' href="#intro" onClick={e => scrollTo(e, menuOpen)}>
          <img src="/assets/logo.svg" alt="Logo" />
        </a>
      </Navbar.Brand>
      <Navbar.Toggle
        id="collapse"
        data-bs-toggle="collapse"
        data-bs-target="#collapse-navbar"
        tabIndex={0}
        className="navbar-btn"
      />
      <Navbar.Collapse id="collapse-navbar">
        <Nav className="mx-auto justify-content-center d-flex mt-3 mt-xl-0">
          <Nav.Item>
            <Nav.Link onClick={scrollTo} href="#expertise">
              Expertise
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link onClick={scrollTo} href="#tech-stack">
              Tech Stack
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link onClick={scrollTo} href="#how-we-work">
              How We Work
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link onClick={scrollTo} href="#portfolio">
              Portfolio
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link onClick={scrollTo} href="#testimonials">
              Testimonials
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="d-block d-xl-none">
            <Nav.Link variant="primary" onClick={scrollTo} href="#contact-us" className="bg-gradient btn btn-primary contact-us-btn">
              Contact Us
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <div className="d-xl-flex justify-content-center contact-us-container-nav d-none">
          <Button variant="primary" onClick={scrollTo} href="#contact-us" className="bg-gradient contact-us-btn navbar-btn">
            Contact Us
          </Button>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavigationBar;
