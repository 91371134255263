import React, { useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';

function Testimonials() {
  const ref = useRef(null);
  return (
    <section className="testimonials-container" id="testimonials">
      <Container fluid className="p-0 container-xl">
        <Row className="m-0">
          <Col
            xxl={{ span: 3, offset: 1 }}
            xl={{ span: 4, offset: 0 }}
            xs={12}
            sm={{ span: 10, offset: 1 }}
            className="p-0"
          >
            <span className="container-title">
              What Customers Say About <span>Cryptide</span>
            </span>
            <div className="testimonials-controls d-none flex-row d-xl-flex p-0">
              <Button
                onClick={() => {
                  ref.current.prev();
                }}
                className="testimonials-control bg-transparent"
              >
                <Image src="assets/arrow.svg" />
              </Button>
              <Button
                onClick={() => {
                  ref.current.next();
                }}
                className="testimonials-control bg-transparent"
              >
                <Image src="assets/arrow.svg" />
              </Button>
            </div>
          </Col>
          <Col xxl={{ span: 6, offset: 1 }} xl={{ span: 7, offset: 1 }} xs={12} className="p-0">
            <Carousel
              ref={ref}
              interval={null}
              keyboard={false}
              controls={false}
              indicators={false}
            >
              <Carousel.Item className="testimonials-item">
                <Row className="align-items-center reviewer-block">
                  <Col xs={12} className="text-center col-md-auto p-0">
                    <Image src="assets/testimonial-solidblock.png" className="reviewer-photo" />
                  </Col>
                  <Col xs={12} md={5} className="text-center text-md-start p-0">
                    <span className="reviewer-name">Yael Tamar</span>
                    <span className="reviewer-job">CEO, SolidBlock</span>
                  </Col>
                  <Col md className="d-none d-md-flex p-0 justify-content-end">
                    <Image src="assets/union-logo-2.svg" />
                  </Col>
                </Row>
                <span className="testimonial-text">
                  {`“I am happy to endorse and recommend Cryptide for their exceptional work in the development of decentralized applications. Our team has engaged developers via Cryptide for development of various aspects of our application, and the members of their team have become an integral part of our team and development.\n\nWith their thoughtful and committed engagement, we were able to develop complex smart contracts in a very complicated industry on top of a back-end system for interacting with off-chain data and third-party services. Not only are these Ukrainian programmers highly skilled and experienced in their field, they are dedicated and exceptional human beings.\n\nSomething needs to be said about their leader, Liza, who is very thoughtful, communicative, understanding, and supportive to everyone throughout the process including the team members and clients. I am really happy we were introduced to Liza and her team and I wish them the best of luck in growing their business.”`}
                </span>
              </Carousel.Item>
            </Carousel>
          </Col>
          <Col xs={12} className="d-xl-none">
            <div className="testimonials-controls d-flex flex-row justify-content-center">
              <Button
                onClick={() => {
                  ref.current.prev();
                }}
                className="testimonials-control bg-transparent"
              >
                <Image src="assets/arrow.svg" />
              </Button>
              <Button
                onClick={() => {
                  ref.current.next();
                }}
                className="testimonials-control bg-transparent"
              >
                <Image src="assets/arrow.svg" />
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Testimonials;
