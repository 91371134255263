import React from 'react';
import Container from 'react-bootstrap/Container';
import Stack from 'react-bootstrap/Stack';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import { scrollTo } from '../utils';

function TechStack() {
  return (
    <section className="tech-stack-container" id="tech-stack">
      <Container>
        <Row>
          <Col xs={12} className="d-flex justify-content-center">
            <img src="/assets/union-logo.svg" alt="Union logo" />
          </Col>
        </Row>
        <Row>
          <Col lg={{ span: 10, offset: 1 }} xs={12} className="section">
            <h2 className="container-title">Tech Stack</h2>
          </Col>
        </Row>
        <Row>
          <Col
            xs={3}
            className="align-items-center d-flex flex-column tech-stack-item border-bottom border-end"
          >
            <Image fluid src="/assets/icons/ic_eth.svg" />
            <span>Ethereum</span>
          </Col>
          <Col
            xs={3}
            className="align-items-center d-flex flex-column tech-stack-item border-bottom border-end"
          >
            <Image fluid src="/assets/icons/ic_polygon.svg" />
            <span>Polygon</span>
          </Col>
          <Col
            xs={3}
            className="align-items-center d-flex flex-column tech-stack-item border-bottom border-end"
          >
            <Image fluid src="/assets/icons/ic_trx.svg" />
            <span>Tron</span>
          </Col>
          <Col
            xs={3}
            className="align-items-center d-flex flex-column tech-stack-item border-bottom"
          >
            <Image fluid src="/assets/icons/ic_bsc.svg" />
            <span>BSC</span>
          </Col>
        </Row>
        <Row>
          <Col
            xs={3}
            className="align-items-center d-flex flex-column tech-stack-item border-bottom border-end"
          >
            <Image fluid src="/assets/icons/ic_avax.svg" />
            <span>Avalanche</span>
          </Col>
          <Col
            xs={3}
            className="align-items-center d-flex flex-column tech-stack-item border-bottom border-end"
          >
            <Image fluid src="/assets/icons/ic_solidity.svg" />
            <span>Solidity</span>
          </Col>
          <Col
            xs={3}
            className="align-items-center d-flex flex-column tech-stack-item border-bottom border-end"
          >
            <Image fluid src="/assets/icons/ic_cosmos.svg" />
            <span>Cosmos</span>
          </Col>
          <Col
            xs={3}
            className="align-items-center d-flex flex-column tech-stack-item border-bottom"
          >
            <Image fluid src="/assets/icons/ic_polkadot.svg" />
            <span>Polkadot</span>
          </Col>
        </Row>
        <Row>
          <Col xs={3} className="align-items-center d-flex flex-column tech-stack-item border-end">
            <Image fluid src="/assets/icons/ic_aptos.svg" />
            <span>Aptos</span>
          </Col>
          <Col xs={3} className="align-items-center d-flex flex-column tech-stack-item border-end">
            <Image fluid src="/assets/icons/ic_stellar.svg" />
            <span>Stellar</span>
          </Col>
          <Col xs={3} className="align-items-center d-flex flex-column tech-stack-item border-end">
            <Image fluid src="/assets/icons/ic_near.svg" />
            <span>Near</span>
          </Col>
          <Col xs={3} className="align-items-center d-flex flex-column tech-stack-item">
            <Image fluid src="/assets/icons/ic_sol.svg" />
            <span>Solana</span>
          </Col>
        </Row>
        <Stack className="text-center another-technology col-10 mx-auto align-items-center">
          <span>Another technology stack required?</span>
          <span>Get in touch with us and we’ll make our best to fit your need!</span>
          <Button variant="primary" href="#contact-us" onClick={scrollTo} className="bg-gradient contact-us-btn">
            Contact Us
          </Button>
        </Stack>
      </Container>
    </section>
  );
}

export default TechStack;
