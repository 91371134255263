import ScrollSpy from './ScrollSpy';

/**
 *
 * @param {string|HTMLElement} el - Selector to nav menu.
 * @param {{
*  sectionSelector: string;
*  targetSelector: string;
*  offset: number;
*  hrefAttribute: string;
*  activeClass: string;
* }} options - Options
*/
function scrollspy (el, options = {}) {
   const scrollSpy = new ScrollSpy(el, options);

   window.onload = function () {
       scrollSpy.onScroll();
   };
   window.addEventListener('scroll', () => {
       scrollSpy.onScroll();
   });

   return scrollSpy;
};

export default scrollspy;
