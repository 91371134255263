import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Stack from 'react-bootstrap/Stack';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

const sendMail = async (mail) => {
  return fetch('/submit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(mail),
  });
};
const LINKEDIN_URL = 'https://www.linkedin.com/company/cryptidedev/';

function ContactUs() {
  const [sent, setSent] = useState(null);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({});
  const [isSending, setIsSending] = useState(false);

  const setField = (field, value) => {
    setData({
      ...data,
      [field]: value
    });
    const updatedErrors = { ...errors };
    delete updatedErrors[field];
    setErrors(updatedErrors)
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    if (Object.keys(errors).length) return;

    const form = event.currentTarget;
    if (!form.checkValidity()) {
      form.classList.add('was-validated');
      return;
    }

    setIsSending(true);

    window.grecaptcha.enterprise.ready(() => {
      window.grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: process.env.REACT_APP_RECAPTCHA_ACTION }).then(async (token) => {
        try {
          const { name, email, message } = data;

          const res = await sendMail({ name, email, message, token });
          const json = await res.json();

          const serverErrors = json.errors?.reduce((val, err) => {
            if (typeof err === 'string') {
              throw Error(err);
            } else if (!!err?.type) {
              val[err.path] = err.msg;
            }
            return val;
          }, {})

          if (serverErrors && Object.keys(serverErrors).length) {
            setErrors(serverErrors);
            return;
          }

          setSent(true);
        } catch (err) {
          console.error(err);
          setSent(false);
        } finally {
          setIsSending(false);
        }
      });
    });
  };

  useEffect(() => {
    const form = document.getElementById('contactUsForm');
    form?.addEventListener('submit', async (event) => {
      event.preventDefault();
      if (!form.checkValidity()) form.classList.add('was-validated');
    });
  }, []);

  return (
    <section className="contact-us-container" id="contact-us">
      <Container>
        <Row>
          <Col xl={4} xs={12} className="p-0">
            <Stack className="text-center text-xl-start">
              <Image src="/assets/union-logo.svg" alt="Union logo" className="logo" />
              <h2 className="container-title">Let’s Get In Touch!</h2>
              <span className="container-description">
                {'Tell us a little about your project\nand we’ll do our best to meet your needs!'}
              </span>
              <div className="d-none d-xl-flex flex-row align-items-center social-media">
                <Button className="linkedin-btn bg-transparent" href={LINKEDIN_URL} target='_blank'>
                  <Image src="assets/linkedin.svg" />
                </Button>
                <Image src="assets/ua.svg" />
                <span>Ukraine</span>
              </div>
            </Stack>
          </Col>
          <Col
            xl={{ span: 6, offset: 2 }}
            xs={12}
            className={`contact-us-form-wrapper position-relative p-0 ${sent !== null ? 'd-flex justify-content-center' : ''}`}
          >
            {sent === null && (
              <Form
                className={`contact-us-form ${isSending ? 'opacity-50' : ''}`}
                id="contactUsForm"
                action="send"
                encType="multipart/form-data"
                autoComplete="off"
                autoCapitalize="on"
                onSubmit={onSubmit}
              >
                <Form.Group className="hasValidation">
                  <Form.Control
                    value={data.name ?? ''}
                    title=""
                    id="inputName"
                    required
                    type="text"
                    name="name"
                    placeholder="Your Name"
                    className={`input ${!!errors?.name ? 'invalid' : 'valid'}`}
                    isInvalid={!!errors?.name}
                    onChange={(e) => setField('name', e.target.value)}
                  />
                  <Form.Control.Feedback type='invalid' className="input-feedback">
                    {errors?.name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    value={data.email ?? ''}
                    title=""
                    id="inputEmail"
                    required
                    type="email"
                    name="email"
                    placeholder="Your Email"
                    className={`input ${!!errors?.email ? 'invalid' : 'valid'}`}
                    isInvalid={!!errors?.email}
                    onChange={(e) => setField('email', e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid" className="input-feedback">
                    {errors?.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    as="textarea"
                    value={data.message ?? ''}
                    required
                    name="message"
                    id="inputMessage"
                    title=""
                    className={`input ${!!errors?.message ? 'invalid' : 'valid'}`}
                    rows={6}
                    placeholder="Tell us about your project"
                    isInvalid={!!errors?.message}
                    onChange={(e) => setField('message', e.target.value)}
                  />
                  <div className="invalid-feedback input-feedback">{errors?.message}</div>
                </Form.Group>
                <Button type="submit" className="bg-gradient align-self-xl-end">
                  Send Message
                </Button>
                <div
                  className="g-recaptcha"
                  data-sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  data-size="invisible"
                  data-theme="dark"
                ></div>
              </Form>
            )}
            {isSending && (
              <div className="loader-container">
                <Spinner animation="border" role="status" />
              </div>
            )}

            {sent === true && (
              <div className="d-flex flex-column justify-content-center align-items-center text-center">
                <div className="send-result-icon bg-gradient">
                  <Image src="assets/check.svg" />
                </div>
                <span className="send-result-message">{`Your message sent!\nWe’ll get back to you as fast as possible!`}</span>
              </div>
            )}
            {sent === false && (
              <div className="d-flex flex-column justify-content-center align-items-center text-center">
                <div className="send-result-icon">
                  <Image src="assets/x.svg" />
                </div>
                <span className="send-result-message">
                  {`Something went wrong!\nTry to contact us on `}
                  <a
                    rel="noreferrer"
                    href={LINKEDIN_URL}
                    target="_blank"
                  >
                    LinkedIn
                  </a>
                  {`\nOr directly via email `}
                  <a
                    href="mailto:contact@cryptide.dev"
                  >
                    contact@cryptide.dev
                  </a>
                </span>
              </div>
            )}
          </Col>
          <Col xs={12} className="d-block d-xl-none">
            <div className="d-flex flex-row align-items-center social-media">
              <Button
                className="linkedin-btn bg-transparent"
                href="https://www.linkedin.com/company/cryptidedev/"
                target="_blank"
              >
                <Image src="assets/linkedin.svg" />
              </Button>
              <Image src="assets/ua.svg" />
              <span>Ukraine</span>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="recaptcha-info text-center position-absolute">
        This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" rel="noreferrer" target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms" rel="noreferrer" target="_blank">Terms of Service</a> apply.
      </div>
    </section>
  );
}

export default ContactUs;
